import { FC, useEffect } from 'react';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';

type IProps = {
    visible: boolean;
    onClose: () => void;
};

export const TradeInCarAutoProffModal: FC<IProps> = ({ visible, onClose }) => {
    useEffect(() => {
        const autoProffInit = () => {
            window.autoproff({
                group: '46320',
                style: {
                    '.ap-popup-stepbutton:not(.ap-popup-stepbutton-outline)': { 'background-color': '#0889BE', 'border-color': '#0889BE' },
                    '.ap-popup-stepcompleted-license': { 'background-color': '#0889BE' },
                },
                motor: 'dmr',
                locale: 'da',
                embed_selector: '.ap-embed',
                integrated_button_selector: '.ap-cta',
                autoopen: false,
                button: false,
            });
        };
        const autoProffScriptCheck = document.getElementById('autoProffScript');
        if (visible) {
            if (!autoProffScriptCheck) {
                const autoProffScript = document.createElement('script');
                autoProffScript.setAttribute('src', 'https://webkit.autoproff.com/js/widget.js?v=1712051982177');
                autoProffScript.id = 'autoProffScript';
                document.head.appendChild(autoProffScript);
                autoProffScript.onload = () => autoProffInit();
            } else {
                autoProffInit();
            }
        } else {
            const container = document.getElementsByClassName('ap-embed');
            if (container && container.length > 0) {
                container[0].innerHTML = '';
            }
            const widgetOverlay = document.getElementById('ap-widget-overlay');
            if (widgetOverlay) {
                widgetOverlay.remove();
            }
        }
    }, [visible]);
    return (
        <SidePanel isVisible={visible} cancelAction={onClose}>
            <SidePanelLayout closeSidePanel={onClose}>
                <div className="ap-embed"></div>
            </SidePanelLayout>
        </SidePanel>
    );
};
