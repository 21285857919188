import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

export const StyledToolTipContainer = styled.div`
    position: relative;
`;

export const StyledToolTipText = styled(animated.span)<{ disableMinWidth: boolean; openDirection: 'left' | 'right' }>`
    position: absolute;
    bottom: calc(100% + 10px);
    background-color: #d8d8d8;
    padding: 15px;
    color: #636363;
    min-width: ${(props) => (props.disableMinWidth ? 'unset' : '250px')};
    max-width: 250px;
    line-height: 16px;
    font-size: 11px;
    ${(props) =>
        props.openDirection === 'left'
            ? css`
                  right: -10px;
              `
            : css`
                  left: -30px;
              `}

    &:after {
        content: ' ';
        width: 15px;
        height: 15px;
        background-color: #d8d8d8;
        position: absolute;
        bottom: 0;
        transform: translateY(50%) rotate(45deg);
        ${(props) =>
            props.openDirection === 'left'
                ? css`
                      right: 10px;
                  `
                : css`
                      left: 30px;
                  `}
    }
`;
