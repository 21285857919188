import { VFC } from 'react';
import { useAccordionController } from '../../../hooks/use-accordion-controller';
import { uiTypes } from '../../../lib';
import { Accordion } from '../../shared/accordion';
import { Header, RichFaqWrapper, StyledRichContent, StyledWrapper, QuestionsWrapper, StyledRichFaqSpotHeader } from './rich-faq.styled';
import { isNullOrEmpty } from '../../../utils/helpers';

export type RichFaqProps = {
    headerText?: string;
    headerType?: uiTypes.HeaderType;
    headerSize?: uiTypes.HeaderSize;
    backgroundColor?: string;
    textColor?: string;
    questions?: {
        question: string;
        answer: string;
        headerType?: uiTypes.HeaderType;
        headerSize?: uiTypes.HeaderSize;
    }[];
    scrollAnchorId: string;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const RichFaq: VFC<RichFaqProps> = ({ headerText, headerType, headerSize, backgroundColor, textColor, questions, scrollAnchorId }) => {
    const { registerAccordion } = useAccordionController();

    if (!questions) {
        return null;
    }

    return (
        <StyledWrapper id={scrollAnchorId}>
            {isNullOrEmpty(headerSize) ? (
                <Header as={headerType}>{headerText}</Header>
            ) : (
                <StyledRichFaqSpotHeader headerType={headerType} headerSize={headerSize}>
                    {headerText}
                </StyledRichFaqSpotHeader>
            )}

            <QuestionsWrapper bgColor={'#' + backgroundColor}>
                {questions.map((x, index) => {
                    return (
                        <RichFaqWrapper key={`question:${index}`}>
                            <Accordion
                                title={x.question}
                                titleType={x.headerType}
                                titleSize={x.headerSize}
                                variant="primary"
                                border="bottom"
                                headerSize="sm"
                                contentPaddingX={20}
                                contentPaddingY={5}
                                backgroundColor={backgroundColor}
                                textColor={textColor}
                                {...registerAccordion(index)}
                            >
                                <StyledRichContent dangerouslySetInnerHTML={{ __html: x.answer.replace('/media/', `${MEDIA_URL}/media/`) }} />
                            </Accordion>
                        </RichFaqWrapper>
                    );
                })}
            </QuestionsWrapper>
        </StyledWrapper>
    );
};
