import React, { FC, Fragment, useMemo } from 'react';
import { IContentSpots } from './content-spots.props';
import styled from 'styled-components';
import { DynamicContentBoxes } from './dynamic-content-boxes';
import { CenteredBlock } from '../structural-blocks/centered-block.component';
import { mapBundleListToProductCards } from '../../lib/mappers/vehicle/product-card.mapper';
import { MarketingProductCard } from '../../lib/view-models/vehicle';
import { markContentSpotAsAboveFold } from '../../utils/helpers/content-spots.helpers';
import { CarExplorationModule } from './car-exploration/car-exploration.component';
import { BundleSpot } from '../hire/six-plus-six/bundle-spot/bundle-spot.component';
import { FindDealershipModule } from '../shared/find-dealership-module/find-dealership-module.component';
import { FindDealershipViaMap } from './find-dealership-via-map/find-dealership-via-map.component';
import { SizeGuideReference } from './size-guide/size-guide-reference.component';
import { SizeGuide } from './size-guide/size-guide.component';
import { FullScreenCampaign } from './full-screen-campaign';
import { FullScreenCarFinder } from './full-screen-car-finder';
import { SplitHeroManager } from './split-hero-manager';
import { UspBarList } from '../usp-bar-list/usp-bar-list.component';
import { RichFaq } from './rich-faq';
import { FullscreenMedia } from './fullscreen-media';
import { SplitMediaList } from './split-media-list';
import { UmbracoForms } from './umbraco-forms';
import { ComparisonModuleUi } from '../plus-sites-shared/comparison-module';
import { VehicleProductList } from '../vehicle/vehicle-product-list';
import { VehicleProductRibbon } from '../vehicle/vehicle-product-ribbon';
import { CustomerPortal } from './customer-portal/customer-portal.component';
import { EmployeeModule } from './employee-module/employee-module.component';
import { ShopProductListSpot } from './shop-product-list/shop-product-list.component';
import { HorizontalDividerSpot } from './horizontal-divider-spot/horizontal-divider-spot';
import { Testimonial } from './testimonial/testimonial.component';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ContentSpots: FC<IContentSpots> = ({ contentSpotSettings: spotList, context, globalPlpSettings }) => {
    const updatedSpotList = useMemo(() => markContentSpotAsAboveFold(spotList), [spotList]);
    return (
        <PageWrapper>
            {updatedSpotList.map((x, index) => {
                switch (x.alias) {
                    case 'carExplorationModule':
                        return <CarExplorationModule key={`${x.alias}-${index}-${context.pageId}`} spot={x} />;
                    case 'sizeGuideReferenceSpot':
                        return <SizeGuideReference key={`${x.alias}-${index}-${context.pageId}`} spot={x} />;
                    case 'sizeGuideSpot':
                        return <SizeGuide key={`${x.alias}-${index}-${context.pageId}`} spot={x} />;
                    case 'fullScreenCampaign':
                        return <FullScreenCampaign key={`${x.alias}-${index}-${context.pageId}`} heroSettings={x} />;
                    case 'fullScreenCarFinder':
                        return globalPlpSettings ? (
                            <FullScreenCarFinder
                                key={`${x.alias}-${index}-${context.pageId}`}
                                heroSettings={x}
                                globalPlpSettings={globalPlpSettings}
                            />
                        ) : null;
                    case 'splitHero':
                        return globalPlpSettings ? (
                            <SplitHeroManager key={`${x.alias}-${index}-${context.pageId}`} splitHero={x} globalPlpSettings={globalPlpSettings} />
                        ) : null;
                    case 'uSPList':
                        return (
                            <UspBarList
                                key={`${x.alias}-${index}-${context.pageId}`}
                                uspBarList={x.items}
                                uspPerRow={x.uspPerRow}
                                spotHeader={x.spotHeader}
                                headerType={x.headerType}
                                headerSize={x.headerSize}
                                spotDescription={x.spotDescription}
                                cta={x.cta}
                                ctaColor={x.ctaColor}
                                scrollAnchorId={x.scrollAnchorId}
                                isAboveFold={!!x.isAboveFold}
                            />
                        );
                    case 'richContentFaqList':
                        return (
                            <RichFaq
                                key={`${x.alias}-${index}-${context.pageId}`}
                                headerText={x.headerText}
                                headerType={x.headerType}
                                headerSize={x.headerSize}
                                backgroundColor={x.backgroundColor}
                                textColor={x.textColor}
                                questions={x.items.map((x) => {
                                    return {
                                        question: x.headerText,
                                        answer: x.text,
                                        headerType: x.headerType,
                                        headerSize: x.headerSize,
                                    };
                                })}
                                scrollAnchorId={x.scrollAnchorId}
                            />
                        );
                    case 'fullscreenMediaList':
                        return <FullscreenMedia key={`${x.alias}-${index}-${context.pageId}`} content={x} />;
                    case 'splitMediaList':
                        return <SplitMediaList key={`${x.alias}-${index}-${context.pageId}`} content={x} />;
                    case 'forms':
                        return (
                            <CenteredBlock maxWidth={880} key={`${x.alias}-${index}-${context.pageId}`}>
                                <UmbracoForms
                                    form={x.form}
                                    header={x.formHeader}
                                    subtext={x.formSubtext}
                                    pageId={context.pageId}
                                    trackingStrategy={x.trackingStrategy ? x.trackingStrategy[0] : undefined}
                                    scrollAnchorId={x.scrollAnchorId}
                                />
                            </CenteredBlock>
                        );
                    case 'comparisonTable':
                        return <ComparisonModuleUi key={`${x.alias}-${index}-${context.pageId}`} tableData={x} headerPosition="Center" />;
                    case 'productListWithFilter':
                        if (globalPlpSettings) {
                            return (
                                <VehicleProductList
                                    key={`${x.alias}-${index}-${context.pageId}`}
                                    plpConfig={{ ...x, globalPlpSettings: globalPlpSettings }}
                                    pageId={context.pageId}
                                    scrollAnchorId={x.scrollAnchorId}
                                    initialProductListData={x.initialProductListData}
                                    initialProducts={x.initialProducts}
                                    initialFacetsArray={x.initialFacetsArray}
                                    initialActiveFilters={x.initialActiveFilters}
                                    isAboveFold={x.isAboveFold}
                                />
                            );
                        }
                        return null;
                    case 'productListWithoutFilter':
                        if (globalPlpSettings) {
                            return (
                                <VehicleProductList
                                    key={`${x.alias}-${index}-${context.pageId}`}
                                    plpConfig={{ ...x, globalPlpSettings: globalPlpSettings }}
                                    pageId={context.pageId}
                                    scrollAnchorId={x.scrollAnchorId}
                                    initialProductListData={x.initialProductListData}
                                    initialProducts={x.initialProducts}
                                    initialFacetsArray={x.initialFacetsArray}
                                    initialActiveFilters={x.initialActiveFilters}
                                    isAboveFold={x.isAboveFold}
                                    showFacets={false}
                                />
                            );
                        }
                        return null;
                    case 'productRibbon':
                        return <VehicleProductRibbon key={`${x.alias}-${index}-${context.pageId}`} content={x} />;
                    case 'findDealership':
                        return <FindDealershipModule key={`${x.alias}-${index}-${context.pageId}`} content={x} />;
                    case 'sixPlusSixBundle': {
                        const marketingProducts: Array<MarketingProductCard> =
                            x.marketingProductCards.map((x) => ({ ...x, type: 'marketing' })) ?? [];

                        return (
                            <BundleSpot
                                key={`${x.alias}-${index}-${context.pageId}`}
                                content={x}
                                pageId={context.pageId}
                                bundleUSPs={globalPlpSettings?.hireBundleUSPs ?? []}
                                bundles={x.bundles ?? []}
                                bundleCards={mapBundleListToProductCards(
                                    x.bundles ?? [],
                                    globalPlpSettings?.hireBundleUSPs ?? [],
                                    x.carsInBundle ?? []
                                )}
                                marketingProductCards={marketingProducts}
                                bundleDialogHeader={globalPlpSettings?.bundleDialogHeader ?? ''}
                                bundleDialogStartupFrom={globalPlpSettings?.bundleDialogStartupFrom ?? ''}
                                bundleDialogOverviewOfferHeader={globalPlpSettings?.bundleDialogOverviewOfferHeader ?? ''}
                                bundleDialogPaymentPlanHeader={globalPlpSettings?.bundleDialogPaymentPlanHeader ?? ''}
                                bundleDialogSubmitButton={globalPlpSettings?.bundleDialogSubmitButton ?? ''}
                                bundleDialogReceiptHeader={globalPlpSettings?.bundleDialogReceiptHeader ?? ''}
                                bundleDialogReceiptDescription={globalPlpSettings?.bundleDialogReceiptDescription ?? ''}
                                bundleDialogReceiptSubmitButton={
                                    globalPlpSettings?.bundleDialogReceiptSubmitButton &&
                                    globalPlpSettings?.bundleDialogReceiptSubmitButton?.length > 0
                                        ? globalPlpSettings?.bundleDialogReceiptSubmitButton[0]
                                        : undefined
                                }
                            />
                        );
                    }
                    case 'dynamicContentBoxes':
                        return <DynamicContentBoxes content={x} key={`${x.alias}-${index}-${context.pageId}`} />;
                    case 'findDealershipSpot':
                        return (
                            <Fragment key={`${x.alias}-${index}-${context.pageId}`}>
                                <div id={x.scrollAnchorId} style={{ marginTop: '-10px' }}>
                                    <p style={{ height: '0', opacity: 0, visibility: 'hidden' }}>Map</p>
                                </div>
                                <FindDealershipViaMap spot={x} />
                            </Fragment>
                        );
                    case 'customerPortal':
                        return <CustomerPortal spot={x} key={`${x.alias}-${index}-${context.pageId}`} />;
                    case 'employeeModule':
                        return <EmployeeModule spot={x} key={`${x.alias}-${index}-${context.pageId}`} />;
                    case 'shopProductList':
                        if (globalPlpSettings) {
                            return (
                                <ShopProductListSpot
                                    spot={x}
                                    key={`${x.alias}-${index}-${context.pageId}`}
                                    pageId={context.pageId}
                                    productlistSettings={globalPlpSettings}
                                />
                            );
                        }
                        return null;

                    case 'horizontalDividerSpot': {
                        return <HorizontalDividerSpot key={`${x.alias}-${index}-${context.pageId}`} spot={x} isFirstSpotInList={index === 0} />;
                    }
                    case 'testimonialSpot': {
                        return <Testimonial content={x} key={`${x.alias}-${index}-${context.pageId}`} />;
                    }

                    default:
                        return null;
                }
            })}
        </PageWrapper>
    );
};
