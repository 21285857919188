import { VFC } from 'react';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import { umbraco } from '../../../lib/api';
import { CustomerSavingsStore } from '../../../lib/state/plus-sites/savings';
import { formatPrice } from '../../../utils/helpers';
import { Button } from '../../shared';
import { SavingsTable } from '../../shared/savings-table';
import {
    BorderBox,
    Ctas,
    CtasAndValues,
    Footnote,
    Header,
    Result,
    ResultDescription,
    ResultWrapper,
    ResultsWrapper,
    SavingsResultWrapper,
    StyledText,
} from './savings-details.styled';

type Props = {
    adjustSavings: () => void;
    content?: umbraco.CustomerSavingsContent;
    goToSignUp: () => void;
};

export const SavingsDetails: VFC<Props> = ({ adjustSavings, content, goToSignUp }) => {
    const { savings, yearlyMembership, yearlyCompleteMembership, savingsResult } = CustomerSavingsStore.useStoreState((state) => state);

    return (
        <BorderBox>
            <Header>{content?.detailsHeader}</Header>

            <StyledText>{content?.detailsSubheader}</StyledText>

            <SavingsTable
                savings={savings}
                yearlyMembership={yearlyMembership}
                yearlyCompleteMembership={yearlyCompleteMembership}
                membershipSum={savingsResult.membershipSum ?? 0}
                nonMembershipSum={savingsResult.noneMembershipSum ?? 0}
                completeMembershipSum={savingsResult.completeMembershipSum ?? 0}
                content={content}
            />

            <Footnote>{content?.detailsFootnote}</Footnote>

            <SavingsResultWrapper>
                <Header>{content?.detailsResultHeader}</Header>
                <CtasAndValues>
                    <ResultsWrapper>
                        {savingsResult.noneMembershipSum !== undefined &&
                            savingsResult?.membershipSum !== undefined &&
                            yearlyMembership !== undefined && (
                                <ResultWrapper>
                                    <Result>
                                        {formatPrice(Math.round(savingsResult.noneMembershipSum - (savingsResult.membershipSum + yearlyMembership)))}{' '}
                                        {Abbreviations.KR_SLASH_AAR}
                                    </Result>
                                    <ResultDescription>{content?.membershipText}</ResultDescription>
                                </ResultWrapper>
                            )}

                        {yearlyCompleteMembership !== undefined &&
                            savingsResult.noneMembershipSum !== undefined &&
                            savingsResult?.completeMembershipSum !== undefined && (
                                <ResultWrapper>
                                    <Result>
                                        {formatPrice(
                                            Math.round(
                                                savingsResult.noneMembershipSum - (savingsResult.completeMembershipSum + yearlyCompleteMembership)
                                            )
                                        )}{' '}
                                        {Abbreviations.KR_SLASH_AAR}
                                    </Result>
                                    <ResultDescription>{content?.completeMemberText}</ResultDescription>
                                </ResultWrapper>
                            )}
                    </ResultsWrapper>

                    <Ctas>
                        <Button variant="light" onClick={adjustSavings}>
                            <p>{content?.backToCalculationsText}</p>
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                goToSignUp();
                            }}
                        >
                            <p>{content?.savingsResultCtaText}</p>
                        </Button>
                    </Ctas>
                </CtasAndValues>
            </SavingsResultWrapper>
        </BorderBox>
    );
};
