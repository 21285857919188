import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getPageByDataTypeAlias, getPageByDataTypeAliasGeneric, umbraco } from '../../../lib/api';
import { DealershipInformationPage, StarmarkDealershipPage, orgTypes, sharedTypes } from '../../../lib/api/models/umbraco';
import { hesselViewModels } from '../../../lib/view-models';
import { DealershipContactInfo } from '../../organization/dealership-contact-info/dealership-contact-info.component';
import { FindDealershipViaMap } from '../../spots/find-dealership-via-map/find-dealership-via-map.component';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { FeatureToggleContext } from '../../../feature-toggle/feature-toggle.provider';

type PdpFindDealershipProps = {
    selectedProduct: hesselViewModels.ProductDetails;
    pageId: string;
    spotId?: string;
    staticImage?: sharedTypes.Image;
};

const PdpFindDealershipMemo: React.FC<PdpFindDealershipProps> = ({ selectedProduct, pageId, spotId, staticImage }) => {
    const { setDealershipHasTestDrives, setDealershipLocationLabel } = ProductDetailsStore.useStoreActions((actions) => actions);
    const [usedVehicleDealership, setUsedVehicleDealership] = useState<{
        dealership: orgTypes.DealershipWithGeoInfo | undefined;
        texts: {
            header: string;
            description: string;
            writeToUsText: string;
            email: string;
            phone: string;
        };
    }>({
        dealership: undefined,
        texts: {
            header: '',
            description: '',
            writeToUsText: '',
            email: '',
            phone: '',
        },
    });

    /**
     * Refer to https://jira.impact.dk/browse/EJH-1422 for more information
     */
    useEffect(() => {
        const getStarmarkDealership = async () => {
            const [starmarkDealership, error] = await getPageByDataTypeAlias('starmarkDealershipInformation');
            if (error || !starmarkDealership) return;
            if (starmarkDealership && !error) {
                const starmarkPage = starmarkDealership.filter(
                    (x) => x.contentTypeAlias === 'starmarkDealershipInformation' && x.hovedafdelingId === selectedProduct.locationId?.toString()
                );
                if (!starmarkPage || starmarkPage.length === 0) return;

                const dealership = starmarkPage[0] as StarmarkDealershipPage;
                const mappedDealership: orgTypes.DealershipWithGeoInfo = {
                    dealershipId: dealership.autolineId,
                    hovedafdelingId: dealership.hovedafdelingId,
                    header: dealership.name,
                    lat: dealership.latitude,
                    lng: dealership.longitude,
                    city: dealership.city,
                    zipcode: dealership.zipcode,
                    phone: dealership.phone,
                    address: dealership.address,
                    email: dealership.eMail,
                    url: dealership.url,
                    departments: [],
                    supportedBrands: [],
                    employeesPlaceholderImage: undefined,
                    writeUsForm: dealership.writeUsForm?.[0] ?? undefined,
                    certificates: [],
                    letter: 'A',
                    displayName: dealership.displayName,
                    testDriveOpeningHours: {
                        openingHours: [],
                        specialDays: [],
                    },
                    bookWorkshopModalTexts: {
                        bookWorkshopHeader: '',
                        bookWorkshopDescription: '',
                        bookWorkshopModalButtonText: '',
                    },
                    dealershipImage: dealership.dealershipImage,
                };
                const dealershipHasTestDrives = mappedDealership.testDriveOpeningHours.openingHours.length > 0;
                setDealershipHasTestDrives({ hasTestDrives: dealershipHasTestDrives });
                setDealershipLocationLabel({ dealershipLocationLabel: dealership.dealershipLabel });
                setUsedVehicleDealership({
                    dealership: mappedDealership,
                    texts: {
                        header: dealership.contactInformationHeader,
                        description: dealership.contactInformationDescription,
                        writeToUsText: 'SKRIV TIL OS',
                        email: dealership.eMail,
                        phone: dealership.phone,
                    },
                });
                return;
            }
        };
        const getDealership = async () => {
            if (!selectedProduct?.locationId) {
                return;
            }

            if (selectedProduct.availability !== 'Used' && selectedProduct.availability !== 'Engros') {
                return;
            }

            if (selectedProduct.availability === 'Engros' && selectedProduct.vehicleType !== 'Van') {
                return;
            }
            if (selectedProduct.availability === 'Used' && selectedProduct.brand === 'Mercedes-Benz' && selectedProduct.vehicleType !== 'Van') {
                await getStarmarkDealership();
                return;
            }

            const [dealerships, error] = await getPageByDataTypeAliasGeneric<Array<DealershipInformationPage>>('dealershipInformation');

            if (!dealerships || !dealerships.length || error) {
                return;
            }
            const vehicleDealership = dealerships.find((x) => x.hovedafdelingId === selectedProduct.locationId?.toString());

            if (vehicleDealership) {
                const dealership: umbraco.DealershipInformationPage = vehicleDealership;

                const mappedDealership: orgTypes.DealershipWithGeoInfo = {
                    dealershipId: dealership.hovedafdelingId,
                    hovedafdelingId: dealership.hovedafdelingId,
                    header: dealership.name,
                    lat: dealership.latitude,
                    lng: dealership.longitude,
                    city: dealership.city,
                    zipcode: dealership.zipcode,
                    phone: dealership.phone,
                    address: dealership.address,
                    email: dealership.eMail,
                    url: dealership.url,
                    departments: dealership.departments,
                    supportedBrands: dealership.supportedBrands,
                    employeesPlaceholderImage: undefined,
                    writeUsForm: dealership.writeUsForm?.[0] ?? undefined,
                    certificates: dealership.certifications,
                    letter: 'A',
                    displayName: dealership.displayName,
                    testDriveOpeningHours: {
                        openingHours: [],
                        specialDays: [],
                    },
                    bookWorkshopModalTexts: {
                        bookWorkshopHeader: dealership.bookWorkshopHeader,
                        bookWorkshopDescription: dealership.bookWorkshopDescription,
                        bookWorkshopModalButtonText: dealership.bookWorkshopModalButton,
                    },
                    dealershipImage: dealership.dealershipImage,
                };
                const dealershipHasTestDrives = dealership.testDriveOpeningHours.length > 0;
                setDealershipHasTestDrives({ hasTestDrives: dealershipHasTestDrives });
                setDealershipLocationLabel({ dealershipLocationLabel: dealership.dealershipLabel });
                if (dealership && !error) {
                    setUsedVehicleDealership({
                        dealership: mappedDealership,
                        texts: {
                            header: dealership.contactInformationHeader,
                            description: dealership.contactInformationDescription,
                            writeToUsText: dealership.contactInformationWriteToUs,
                            email: dealership.eMail,
                            phone: dealership.phone,
                        },
                    });
                }
            } else {
                //At this point we have checked the normal Hessel dealerships for a match. If there is no match, we will check amongst the Starmark dealerships instead.
                await getStarmarkDealership();
                return;
            }
        };

        if (selectedProduct?.availability === 'Used' || (selectedProduct?.availability === 'Engros' && selectedProduct?.vehicleType === 'Van')) {
            getDealership();
        }
    }, [
        selectedProduct.locationId,
        selectedProduct.availability,
        selectedProduct.brand,
        selectedProduct.vehicleType,
        setDealershipHasTestDrives,
        setDealershipLocationLabel,
    ]);

    //Feature flag cleanup EJH-3445
    const featureToggleContext = useContext(FeatureToggleContext);
    const newPdpForm = useMemo(() => {
        const newPdpFormLeadToggle = featureToggleContext.find((x) => x.group === 'VehiclePdpContactForm' && x.toggle === 'EnableNewLeadForm');
        if (newPdpFormLeadToggle) return true;
        return false;
    }, [featureToggleContext]);

    return (
        <>
            {selectedProduct?.availability !== 'Used' && selectedProduct?.availability !== 'Engros' ? (
                <FindDealershipViaMap
                    spot={{
                        brands: [selectedProduct.brand as orgTypes.BrandType],
                        supportedVehicles: [selectedProduct.vehicleType === 'Car' ? 'Cars' : 'Vans'],
                        departments: ['Salg'],
                        alias: 'findDealershipSpot',
                        hideBlock: false,
                        fromDate: new Date(),
                        toDate: new Date(),
                        scrollAnchorId: '',
                        headerType: 'h4',
                        renderGoogleMaps: false,
                        staticImage: staticImage,
                    }}
                    matchOnlySales={true}
                />
            ) : (
                <DealershipContactInfo
                    allDealerships={[]}
                    dealership={usedVehicleDealership.dealership ?? null}
                    description={usedVehicleDealership.texts.description}
                    header={usedVehicleDealership.texts.header}
                    pageId={pageId}
                    writeToUsText={usedVehicleDealership.texts.writeToUsText}
                    writeUsForm={usedVehicleDealership?.dealership?.writeUsForm ?? undefined}
                    usedOnContactPage={false}
                    headerType="h4"
                    spotId={spotId ?? `pdp-find-dealership-${selectedProduct.id}-${selectedProduct?.locationId}`}
                    renderGoogleMap={false}
                    isPdpContext={newPdpForm}
                />
            )}
        </>
    );
};

export const PdpFindDealership = React.memo(PdpFindDealershipMemo);
