import styled from 'styled-components';
import { IconButtonWithLabel } from '../../icon-button-with-label/icon-button-with-label.component';
import { Close } from '../../../icons';
import { mixins } from '../../../../themes';

type StyledInnerCarouselSlideProps = {
    activeIndex: number;
    dragOffset: number;
};

export const StyledInnerCarousel = styled.div`
    display: flex;
    overflow: hidden;
    touch-action: pan-y pinch-zoom;
    user-select: none;
`;

export const StyledInnerCarouselSlide = styled.figure.attrs<StyledInnerCarouselSlideProps>((props) => ({
    style: {
        transform: `translateX(calc(${props.activeIndex * -100}% + ${props.dragOffset}px))`,
    },
}))<StyledInnerCarouselSlideProps>`
    display: grid;
    flex: 0 0 100%;
    height: 100%;
    transition: transform 300ms;
    width: 100%;
`;

export const StyledInnerCarouselImageContainer = styled.div<{ bgColor?: string }>`
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    ${(props) => (props.bgColor && props.bgColor.length > 0 ? `background-color: ${props.bgColor}` : null)}
`;

export const StyledInnerCarouselImage = styled.img<{ showAsCover?: boolean; useSquaredCorners?: boolean; mixBlendMode?: string }>`
    min-height: 100%;
    height: ${(props) => (props.showAsCover === true ? '100%' : 'auto')};
    width: ${(props) => (props.showAsCover === true ? '100%' : 'auto')};
    ${(props) => (props.showAsCover === true ? '' : 'max-width: 100%; max-height: 100%;')};

    object-fit: ${(props) => (props.showAsCover === true ? 'cover' : 'contain')};
    object-position: center center;

    align-self: center;
    ${(props) => (props.mixBlendMode && props.mixBlendMode.length > 0 ? `mix-blend-mode: ${props.mixBlendMode}` : null)}
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)}
`;

export const StyledYouTubePlay = styled.img<{ showAsCover?: boolean }>`
    width: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: 'pointer';
    transform: translate(-25px, -25px);
`;

export const StyledInnerCarouselVideo = styled.video<{ useSquaredCorners?: boolean }>`
    height: 100%;
    min-height: 0;
    object-fit: contain;
    width: 100%;
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)};
`;

export const StyledReadMoreOverlay = styled.div`
    height: 100%;
    width: 100%;
`;

export const StyledReadMoreOverlayContainer = styled.div<{ darkmode: boolean }>`
    position: relative;
    height: 100%;
    width: 100%;

    img {
        ${(props) => (props.darkmode ? 'filter: brightness(50%);' : 'opacity: 0.2;')}
    }

    button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        ${(props) => (props.darkmode ? 'z-index: 1;' : '')}
    }
`;

export const YouTubeWrapper = styled.article`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const StyledClosedButton = styled(IconButtonWithLabel).attrs({
    children: Close({}),
})`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
`;

export const StyledIFrame = styled.iframe<{ useSquaredCorners?: boolean }>`
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)};
`;
