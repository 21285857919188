import { FC, useEffect, useMemo, useState } from 'react';
import {
    StyledBasketToast,
    StyledBasketToastText,
    StyledBeforePrice,
    StyledChooseColorText,
    StyledColorPickerWrapper,
    StyledColorPreviewBox,
    StyledColorPreviewGrid,
    StyledInformationSectionWrapper,
    StyledPrice,
    StyledProductCtaSection,
    StyledProductInformationHeader,
    StyledProductInformationSubheader,
    StyledProductKeyInfoWrapper,
    StyledProductNo,
    StyledPromotionWrapper,
    StyledSizeAndCtaWrapper,
    StyledStockAndProductNoWrapper,
} from './information-section.styled';
import { Button } from '../../../shared';
import { ShopProductDetails, Sku } from '../../../../lib/api/models/shop';
import { getImageCropUrl, getShopPrice } from '../../../../utils/helpers';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { DropdownInput } from '../../../forms/inputs';
import { toast } from 'react-toastify';
import { AddedToBasket } from '../../../../constants/toast-consts';
import Link from 'next/link';
import { StockIndicator } from '../../stock-indicator/stock-indicator.component';
import { ProductInstallationCheckbox } from '../../product-installation-checkbox/product-installation-checkbox.component';
import { StyledInstallationDisclaimer } from '../../product-installation-checkbox/product-installation-checkbox.styled';
import { FitsModel } from './fits-model/fits-model-component';
import { ShopProductDescription } from './product-description/product-description.component';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { PromotionBadges } from '../../promotion-badges/promotion-badge.component';

type Props = {
    product: ShopProductDetails;
    productFamily: Array<ShopProductDetails>;
    setSelectedVariant: (variant: ShopProductDetails) => void;
};

export const InformationSection: FC<Props> = ({ product, productFamily, setSelectedVariant }) => {
    const { addProductToCart } = CartStoreContext.useStoreActions((actions) => actions);
    const { cart, cartUrl } = CartStoreContext.useStoreState((state) => state);
    const selectNewSku = (id: string) => {
        const newSku = product.skus.find((x) => x.id === id);
        if (newSku) setSelectedSku(newSku);
    };

    const [selectedSku, setSelectedSku] = useState<Sku>();

    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();

    const sizeOptions = useMemo(() => {
        return [
            {
                disabled: true,
                displayValue: 'Vælg størrelse',
                value: 'Vælg størrelse',
            },
            ...product.skus.map((x) => {
                return {
                    displayValue: x.size,
                    value: x.id,
                    disabled: x.stockStatus === 'OutOfStock',
                };
            }),
        ];
    }, [product.skus]);

    useEffect(() => {
        if (product.skus.length === 1) {
            setSelectedSku(product.skus[0]);
        }
    }, [product.skus]);

    const discounted = useMemo(() => {
        return product.price < product.originalPrice;
    }, [product.originalPrice, product.price]);

    return (
        <div>
            <StyledInformationSectionWrapper>
                <StyledProductKeyInfoWrapper>
                    {product.promotionIds && product.promotionIds.length > 0 ? (
                        <StyledPromotionWrapper>
                            <PromotionBadges promotions={product.promotionIds} />
                        </StyledPromotionWrapper>
                    ) : null}
                    <StyledProductInformationHeader>{product.name}</StyledProductInformationHeader>
                    {product.shortDescription && product.shortDescription.length > 0 ? (
                        <StyledProductInformationSubheader>{product.shortDescription}</StyledProductInformationSubheader>
                    ) : null}
                    <StyledPrice discounted={discounted}>
                        {getShopPrice(product.price)}{' '}
                        {discounted ? <StyledBeforePrice>{getShopPrice(product.originalPrice)}</StyledBeforePrice> : null}
                    </StyledPrice>
                    <ShopProductDescription description={product.description} />
                    {productFamily.length > 1 ? (
                        <StyledColorPickerWrapper>
                            <p>
                                <StyledChooseColorText>Vælg farve: </StyledChooseColorText> {product.color}
                            </p>
                            <StyledColorPreviewGrid>
                                {productFamily.map((x) => {
                                    return (
                                        <StyledColorPreviewBox
                                            selected={x.id === product.id}
                                            key={x.id}
                                            onClick={() => {
                                                setSelectedVariant(x);
                                            }}
                                        >
                                            <img src={getImageCropUrl(x.resources[0].data, 'productlistsmall')} alt={x.color} />
                                        </StyledColorPreviewBox>
                                    );
                                })}
                            </StyledColorPreviewGrid>
                        </StyledColorPickerWrapper>
                    ) : null}

                    <FitsModel fits={product.fits} />

                    {product.installationRequirement !== 'Ingen montering' && product.installationRequirement !== null ? (
                        <div style={{ marginTop: '25px' }}>
                            <ProductInstallationCheckbox installationRequirement={product.installationRequirement} productId={product.id} />
                            {product.installationRequirement === 'Obligatorisk montering hos Hessel' ? (
                                <div style={{ marginTop: '10px' }}>
                                    <StyledInstallationDisclaimer>
                                        Dette produkt skal monteres af Hessel grundet sikkerhedsregler, derfor er montering obligatorisk ved køb.
                                    </StyledInstallationDisclaimer>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </StyledProductKeyInfoWrapper>
                <StyledProductCtaSection>
                    <StyledSizeAndCtaWrapper hasSkus={product.skus.length > 1}>
                        {product.skus.length > 1 ? (
                            <DropdownInput
                                id="sizePicker"
                                label="Størrelse"
                                onChange={(e) => (e ? selectNewSku(e.value) : null)}
                                options={sizeOptions}
                                isValid={true}
                                canValidateInputField={false}
                                value={
                                    selectedSku
                                        ? sizeOptions.find((x) => x.value === selectedSku.id)
                                        : sizeOptions.find((x) => x.value === 'Vælg størrelse')
                                }
                            />
                        ) : null}
                        <Button
                            disabled={!selectedSku || selectedSku.stockStatus === 'OutOfStock'}
                            variant="primary"
                            showAsBlock={true}
                            onClick={async () => {
                                if (selectedSku) {
                                    const response = await addProductToCart({ productSku: selectedSku.id, cart: cart });
                                    if (response !== 'Error') {
                                        tracker.addToCart(product, 1);
                                        toast.success(
                                            <StyledBasketToast>
                                                <StyledBasketToastText>{AddedToBasket}</StyledBasketToastText>
                                                <Link href={cartUrl}>Gå til kurven</Link>
                                            </StyledBasketToast>
                                        );
                                    } else {
                                        toast.error('Der skete en fejl. Prøv igen senere.');
                                    }
                                }
                            }}
                        >
                            TILFØJ TIL KURV
                        </Button>
                    </StyledSizeAndCtaWrapper>
                    <StyledStockAndProductNoWrapper>
                        {selectedSku && (
                            <>
                                <StockIndicator {...selectedSku} />
                                <StyledProductNo>Pakkenummer: {selectedSku.id}</StyledProductNo>
                            </>
                        )}
                    </StyledStockAndProductNoWrapper>
                </StyledProductCtaSection>
            </StyledInformationSectionWrapper>
        </div>
    );
};
