import React, { VFC, useMemo } from 'react';
import {
    CustomerInfoDesktop,
    StyledFooterDesktop,
    Content,
    OpeningHoursWrapper,
    OpeningHoursContent,
    PaymentWrapper,
    CreditsWrapper,
    CreditCard,
    CtaHandlerWrapper,
    FooterFlexContainer,
    SmallTextDesktop,
} from './footer-desktop.styled';
import Link from 'next/link';
import { SmallText, TextGroup, StyledBrand, SmallLink, ContentTitle, OpeningHoursTitle, StyledFooterLogo } from '../footer.styled';
import { umbraco } from '../../../../lib/api';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import { CenteredBlock } from '../../../structural-blocks/centered-block.component';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { MenuContext } from '../../../mega-menu/mega-menu.component';

type IProps = umbraco.FooterContent & {
    menuContext?: MenuContext;
};

export const FooterDesktop: VFC<IProps> = ({
    footerLogo,
    companyName,
    companyAddress,
    companyEmail,
    cvr,
    telephone,
    columns,
    erhvervColumns,
    openingHours = [],
    paymentIcons,
    menuContext,
}) => {
    const columnsToRender = useMemo(() => {
        if (menuContext === 'erhverv') return erhvervColumns;
        return columns;
    }, [columns, erhvervColumns, menuContext]);
    return (
        <StyledFooterDesktop>
            <CenteredBlock>
                <FooterFlexContainer>
                    {footerLogo ? (
                        <StyledFooterLogo src={MEDIA_URL + footerLogo.src} alt={footerLogo.name} />
                    ) : (
                        <StyledBrand>Ejner Hessel</StyledBrand>
                    )}
                    <CustomerInfoDesktop columns={columnsToRender.length + 1}>
                        <Content>
                            <TextGroup>
                                <p>{companyName}</p>
                            </TextGroup>
                            <TextGroup>
                                <p>{companyAddress}</p>
                            </TextGroup>

                            <TextGroup>
                                <SmallText>CVR nr.: {cvr}</SmallText>
                            </TextGroup>

                            <TextGroup>
                                <p>Tlf. nr.:</p>
                                <Link href={`tel:${telephone}`} passHref={true}>
                                    <SmallLink>{telephone}</SmallLink>
                                </Link>
                            </TextGroup>

                            <TextGroup>
                                <p>E-mail:</p>
                                <Link href={`mailto:${companyEmail}`} passHref={true}>
                                    <SmallLink>{companyEmail}</SmallLink>
                                </Link>
                            </TextGroup>

                            <OpeningHoursWrapper>
                                {openingHours && openingHours.length > 0 ? <OpeningHoursTitle>Åbningstider</OpeningHoursTitle> : null}
                                {openingHours?.map((x, index) => (
                                    <OpeningHoursContent key={`${companyName}-${index}`}>
                                        <SmallText>{x.days}</SmallText>
                                        {x.alias === 'openingHour' && <SmallTextDesktop showAsItalic={!x.isOpen}>{x.hours}</SmallTextDesktop>}
                                        {x.alias === 'openingHourLink' && (
                                            <Link href={x.ctaUrl.url}>
                                                <SmallLink>{x.ctaUrl.name}</SmallLink>
                                            </Link>
                                        )}
                                    </OpeningHoursContent>
                                ))}
                            </OpeningHoursWrapper>

                            {paymentIcons && paymentIcons.length > 0 ? (
                                <PaymentWrapper>
                                    <p>Sikker betaling</p>
                                    <CreditsWrapper>
                                        {paymentIcons.map((x, index) => {
                                            return (
                                                <CreditCard key={`footer-desktop-payment-${index}`}>
                                                    <img src={`${MEDIA_URL}/${x.src}`} alt={x.name} loading="lazy" />
                                                </CreditCard>
                                            );
                                        })}
                                    </CreditsWrapper>
                                </PaymentWrapper>
                            ) : null}
                        </Content>

                        {columnsToRender.map((x, index) => {
                            return (
                                <Content key={`footer-desktop-col-${x.title}-${index}`}>
                                    <ContentTitle>{x.title}</ContentTitle>

                                    <CtaHandlerWrapper>
                                        <CtaHandler ctas={x.links} pageSection="Footer" />
                                    </CtaHandlerWrapper>
                                </Content>
                            );
                        })}
                    </CustomerInfoDesktop>
                </FooterFlexContainer>
            </CenteredBlock>
        </StyledFooterDesktop>
    );
};
