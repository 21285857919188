import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { VehicleAvailability } from '../vehicle-availability/vehicle-availability.component';
import { EnergyRating } from '../energy-rating/energy-rating.component';

export const StyledProductDetailsHeader = styled.header`
    align-items: flex-start;
    justify-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0 5px;

    @media ${device.tablet} {
        gap: 10px;
    }
`;

export const StyledProductDetailsVariantHeading = styled.h1`
    color: ${(props) => props.theme.palette.text.primary};
    font-family: 'Corporate E';
    font-size: 22px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    line-height: 1.16;
    margin: 0 0 0.1em;

    @media ${device.mobile_tablet} {
        font-size: 30px;
    }

    @media ${device.tablet} {
        gap: 10px;
        font-size: 40px;
        line-height: 1.08;
        margin: 0;
    }
`;

export const StyledProductDetailsBrandHeading = styled.span`
    color: ${(props) => props.theme.palette.text.primary};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 13px;
    font-weight: 500;
    grid-area: 1 / 1 / 2 / 3;
    line-height: 1.76;

    @media ${device.tablet} {
        font-size: 17px;
        grid-area: 1 / 1 / 2 / 2;
        line-height: 1.41;
    }
`;

export const StyledLabelsWrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    grid-area: 3 / 1 / 4 / 2;
    @media ${device.tablet} {
        grid-area: 3 / 1 / 4 / 3;
    }
`;

export const StyledProductDetailsVehicleAvailability = styled(VehicleAvailability)`
    font-size: 10px;
`;

export const StyledProductDetailsLocation = styled.p`
    background: #000;
    color: #fff;
    cursor: default;
    font-weight: normal;
    font-family: ${(props) => props.theme.typography.fontFamily};
    line-height: 1.6em;
    font-size: 10px;
    padding: 0 0.4em;
    white-space: nowrap;
    cursor: pointer;
`;

export const StyledProductDetailsEnergyRating = styled(EnergyRating)`
    font-size: 10px;
    grid-area: 3 / 2 / 4 / 3;
    margin-right: 5px;

    @media ${device.tablet} {
        grid-area: 1 / 2 / 2 / 3;
    }
`;

export const StyledAutoDesktopCampaignLabel = styled(StyledProductDetailsLocation)<{ color: string; backgroundColor: string }>`
    background: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
`;

type LabelColor = 'Dark' | 'Gray';

export const StyledDimensionLabel = styled.p<{ color: LabelColor }>`
    background: ${(props) => getLabelColor(props.color)};
    color: #fff;
    cursor: default;
    font-weight: normal;
    font-family: ${(props) => props.theme.typography.fontFamily};
    line-height: 1.6em;
    font-size: 10px;
    padding: 0 0.4em;
    white-space: nowrap;
`;

function getLabelColor(color: LabelColor) {
    switch (color) {
        case 'Dark':
            return '#000';
        case 'Gray':
            return '#688488';

        default:
            return '#000';
    }
}
