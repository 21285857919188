import React, { FC, Fragment, useCallback, useState } from 'react';
import { SidePanel } from '../../shared/modals/side-panel';
import {
    StyledHeader,
    StyledSidePanelLayoutWrapper,
    StyledStepWrapper,
    StyledHr,
    StyledSubheader,
} from './service-agreement-calculator-modal.styled';
import LicensePlateStep from './license-plate-step/license-plate-step.component';
import { StyledButton } from './step/step.styled';
import { CustomerInfoStep } from './customer-info-step/customer-info-step.component';
import { ServiceAgreementStep } from './service-agreements-step/service-agreement-step.component';
import Step from './step/step.component';
import {
    ServiceAgreementCalculatorSettings,
    ServiceAgreementCalculatorStep,
    ServiceAgreementCalculatorStepOne,
    ServiceAgreementCalculatorStepThree,
    ServiceAgreementCalculatorStepTwo,
} from '../../../lib/api/models/umbraco';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { ServiceAgreementCalculatorModalStore } from '../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { useUI } from '../../ui';

type Props = {
    settings: ServiceAgreementCalculatorSettings;
};

const ServiceAgreementCalculatorModal: FC<Props> = ({ settings }) => {
    const { setModalOpen } = ServiceAgreementCalculatorModalStore.useStoreActions((actions) => actions);
    const { modalOpen } = ServiceAgreementCalculatorModalStore.useStoreState((state) => state);

    const ui = useUI();

    const [currentStep, setCurrentStep] = useState(0);
    const [hideEdit, setHideEdit] = useState(false);

    const onNextClick = useCallback(() => {
        setCurrentStep((step) => step + 1);
    }, []);

    const closeModal = () => {
        ui.applyScroll();
        setModalOpen(false);
    };

    const isLastStep = useCallback((index: number) => index === settings.steps.length - 1, [settings.steps.length]);

    const loadStep = (step: ServiceAgreementCalculatorStep, index: number) => {
        const isCompleted = currentStep > index;
        const isOpen = currentStep === index;
        switch (step.alias) {
            case 'step1':
                return (
                    <LicensePlateStep
                        stepSettings={step as ServiceAgreementCalculatorStepOne}
                        onNextStepClick={onNextClick}
                        isCompleted={isCompleted}
                        isOpen={isOpen}
                    />
                );
            case 'step2':
                return (
                    <ServiceAgreementStep
                        stepSettings={step as ServiceAgreementCalculatorStepTwo}
                        onNextStepClick={onNextClick}
                        isCompleted={isCompleted}
                        isOpen={isOpen}
                    ></ServiceAgreementStep>
                );
            case 'step3':
                return (
                    <CustomerInfoStep
                        stepSettings={step as ServiceAgreementCalculatorStepThree}
                        onNextStepClick={onNextClick}
                        isCompleted={isCompleted}
                        isOpen={isOpen}
                        onHideEdit={setHideEdit}
                    />
                );
            case 'step4':
                return (
                    isOpen && (
                        <StyledButton onClick={closeModal} variant="primary">
                            Luk
                        </StyledButton>
                    )
                );
            default:
                break;
        }
    };

    return (
        <SidePanel isVisible={modalOpen} cancelAction={closeModal} variant="md">
            <StyledSidePanelLayoutWrapper id="layout-wrapper" titleIsHidden={isLastStep(currentStep)}>
                <SidePanelLayout closeSidePanel={closeModal}>
                    {!isLastStep(currentStep) && (
                        <>
                            <StyledHeader>{settings.header}</StyledHeader>
                            <StyledSubheader>{settings.subheader}</StyledSubheader>
                            <StyledHr />
                        </>
                    )}
                    {settings.steps.map((step, index) => (
                        <Fragment key={step.alias}>
                            <StyledStepWrapper>
                                <Step
                                    id={`step-${index}`}
                                    index={index}
                                    currentStep={currentStep}
                                    title={step.title}
                                    titleDescription={step.titleDescription}
                                    onEditClick={() => setCurrentStep(index)}
                                    hideEdit={hideEdit}
                                    isLastStep={isLastStep(index)}
                                >
                                    {loadStep(step, index)}
                                </Step>
                            </StyledStepWrapper>
                            {!isLastStep(index) && <StyledHr />}
                        </Fragment>
                    ))}
                </SidePanelLayout>
            </StyledSidePanelLayoutWrapper>
        </SidePanel>
    );
};

export default ServiceAgreementCalculatorModal;
