import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { Checkmark } from '../../icons';
import { Button } from '../../shared';
import { GenericCircle } from '../../shared/shapes/generic-circle';
import { StyledProductDetailHeader } from '../product-details-page/shared/product-detail-page-shared.styled';

export const StyledConfigWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    gap: 5px;
    margin-top: 20px;
`;

export const ColorsWrapper = styled.div`
    margin-top: 30px;
    padding-bottom: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media ${device.tablet} {
        grid-template-columns: repeat(6, 1fr);
    }
`;

export const ColorAndPrice = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const StyledColorCircle = styled(GenericCircle)<{ imageUrl?: string; backgroundColor?: string; selected: boolean }>`
    width: 35px;
    height: 35px;
    background-image: ${(props) => (props.imageUrl ? `url('${props.imageUrl}')` : 'none')};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex-shrink: 0;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'unset')};

    border: ${(props) => (props.selected ? '2.6px solid #0B0B0B;' : 'none')};

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ColorPrice = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
`;

export const StyledCheckmark = styled(Checkmark)`
    border-radius: 50%;
    background-color: black;

    & path {
        stroke: white;
    }
`;

export const StyledConfigIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const StyledInfoButton = styled(Button)`
    margin-top: 12px;
    font-weight: 400;
`;

export const StyledConfigHeader = styled(StyledProductDetailHeader)`
    margin-bottom: 20px;
`;
