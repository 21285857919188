import { useMemo, useState, VFC } from 'react';
import { OrganizationConsts } from '../../../constants';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { BookTestDriveModalSettings, DealershipInformationPage, sharedTypes } from '../../../lib/api/models/umbraco';
import { hesselViewModels } from '../../../lib/view-models';
import { handleUrlClick } from '../../../utils/helpers';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { Button } from '../../shared';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { BookTestDriveModal } from '../book-test-drive-modal/book-test-drive-modal.component';
import {
    DemoVehiclesProductRibbonWrapper,
    Description,
    Header,
    NoResultDescription,
    NoResultHeader,
    NoResultWrapper,
    TextsAndCta,
} from './demo-vehicles-product-ribbon.styled';
import { useProductRibbon } from '../hooks/use-product-ribbon';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { SpecialDay } from '../../../lib/api/models/umbraco/organization.types';
import { VehicleProductListCard } from '../../vehicle/vehicle-product-list/vehicle-product-list-card';
import { FilterConfigurationKey } from '../../../constants/site-consts';

type DemoVehicleProductRibbonProps = {
    header: string;
    description: string;
    footerHeader: string;
    footerDescription: string;
    linkToPlp?: sharedTypes.CtaUrl;
    currentDealership: DealershipInformationPage;
    allDealerships: Array<DealershipInformationPage>;
    demoVehiclesFilterConfigurationId: string | undefined;
    notFoundTexts?: {
        header: string;
        description: string;
        ctaText: string;
    };
    vehicleType: hesselViewModels.VehicleType;
    bookTestDriveModalSettings?: BookTestDriveModalSettings;
    defaultSpecialDays?: SpecialDay[];
};

type DemoVehicleProductRibbonState = {
    showModal: boolean;
    vehicle?: hesselViewModels.ProductCardDetails;
};

export const DemoVehiclesProductRibbon: VFC<DemoVehicleProductRibbonProps> = ({
    currentDealership,
    allDealerships,
    header,
    description,
    footerHeader,
    footerDescription,
    linkToPlp,
    demoVehiclesFilterConfigurationId,
    notFoundTexts = {
        header: 'Der er ingen biler, du kan prøvekøre i denne afdeling i øjeblikket.',
        description: 'Du har mulighed for at se alle biler du kan prøvekøre, på tværs af alle afdelinger.',
        ctaText: 'Se alle biler til prøvekørsel',
    },
    vehicleType,
    bookTestDriveModalSettings,
    defaultSpecialDays,
}) => {
    const [state, setState] = useState<DemoVehicleProductRibbonState>({
        showModal: false,
        vehicle: undefined,
    });

    const { hideScrollBars, applyScrollBars } = useScrollLock();

    const filters = useMemo(() => {
        switch (vehicleType) {
            case 'Car':
                return [
                    FilterConfigurationKey.availability_demo,
                    FilterConfigurationKey.category_car,
                    `${FilterConfigurationKey.locationId}${currentDealership.hovedafdelingId}`,
                ];
            case 'Van':
                return [
                    FilterConfigurationKey.availability_demo,
                    FilterConfigurationKey.category_van,
                    `${FilterConfigurationKey.locationId}${currentDealership.hovedafdelingId}`,
                ];
            default:
                return [];
        }
    }, [currentDealership.hovedafdelingId, vehicleType]);

    const { vehicles: demoVehicles } = useProductRibbon(
        demoVehiclesFilterConfigurationId,
        {
            from: 0,
            take: 1000,
            filters: [...filters],
        },
        vehicleType
    );

    const vehicleToBookTestDrive = useMemo(() => {
        return state.vehicle?.type === 'detailed' ? (state.vehicle as hesselViewModels.ProductCardDetails) : undefined;
    }, [state.vehicle]);

    if (!demoVehicles || demoVehicles.length === 0) {
        //  We need to make sure the returned UI has an id so that users can navigate to it
        return (
            <DemoVehiclesProductRibbonWrapper id={OrganizationConsts.DemoCarsRibbonSpot}>
                <CenteredBlock>
                    <NoResultHeader>{header}</NoResultHeader>

                    <NoResultDescription>
                        <ContentSpotRichText text={description}></ContentSpotRichText>
                    </NoResultDescription>

                    <NoResultWrapper>
                        <Header>{notFoundTexts.header}</Header>
                        <Description>{notFoundTexts.description}</Description>
                        {linkToPlp && linkToPlp.url ? (
                            <Button variant="primary" onClick={() => handleUrlClick(linkToPlp)}>
                                <p>{notFoundTexts.ctaText}</p>
                            </Button>
                        ) : null}
                    </NoResultWrapper>
                </CenteredBlock>
            </DemoVehiclesProductRibbonWrapper>
        );
    }

    return (
        <>
            <DemoVehiclesProductRibbonWrapper id={OrganizationConsts.DemoCarsRibbonSpot}>
                <VehicleProductCardCarousel header={header} text={description} link={linkToPlp}>
                    {demoVehicles.map((product, index) => {
                        return (
                            <VehicleProductListCard
                                key={`${product.id}-${index}`}
                                productCard={product}
                                className="keen-slider__slide"
                                onCardClick={() => {}}
                                allowCarousel={false}
                                ctaSettings={{
                                    onClick: () => {
                                        hideScrollBars();
                                        setState({
                                            ...state,
                                            showModal: true,
                                            vehicle: product as hesselViewModels.ProductCardDetails,
                                        });
                                    },
                                    text: 'Book prøvetur',
                                }}
                            />
                        );
                    })}
                </VehicleProductCardCarousel>

                <CenteredBlock>
                    <TextsAndCta>
                        <Header>{footerHeader}</Header>
                        <Description>{footerDescription}</Description>
                        {linkToPlp && linkToPlp.url ? (
                            <Button variant="light" onClick={() => handleUrlClick(linkToPlp)}>
                                <p>{linkToPlp.name}</p>
                            </Button>
                        ) : null}
                    </TextsAndCta>
                </CenteredBlock>
            </DemoVehiclesProductRibbonWrapper>

            <BookTestDriveModal
                brand={vehicleToBookTestDrive?.brand}
                vehicleItemNumber={vehicleToBookTestDrive?.itemNumber}
                brandModel={vehicleToBookTestDrive?.brandModel}
                name={vehicleToBookTestDrive?.name}
                vehicleType={vehicleToBookTestDrive?.category}
                visible={state.showModal}
                onClose={() => {
                    applyScrollBars();
                    setState({
                        showModal: false,
                        vehicle: undefined,
                    });
                }}
                allDealerships={allDealerships.filter((x) => x.supportedBrands.some((y) => y.brand === state.vehicle?.brand))}
                currentDealership={currentDealership}
                bookTestDriveModalSettings={bookTestDriveModalSettings}
                configurationId={demoVehiclesFilterConfigurationId}
                vehicleLocationId={vehicleToBookTestDrive?.locationId}
                vehicleUrl={vehicleToBookTestDrive?.url ?? ''}
                isUsedCar={true}
                specialDays={[...currentDealership?.testDriveOpeningHours?.[0]?.specialDays, ...(defaultSpecialDays ?? [])]}
                vehicleAvailability={vehicleToBookTestDrive?.availability}
                testDriveAvailability="Available"
                vin={vehicleToBookTestDrive?.vin}
            />
        </>
    );
};
