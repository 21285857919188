import { hesselApiTypes } from '../../api/models/hessel-api';
import { VehicleAvailabilityTypeKeys } from '../../api/models/shared/vehicle.shared';
import { PdpResource } from './pdp-media';

export type VehicleAvailability = typeof VehicleAvailabilityTypeKeys[keyof typeof VehicleAvailabilityTypeKeys];

export const EnergyRatingTypes = {
    APlusPlusPlus: 'A+++',
    APlusPlus: 'A++',
    APlus: 'A+',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    Unknown: 'Unknown',
} as const;

export type EnergyRating = typeof EnergyRatingTypes[keyof typeof EnergyRatingTypes];

export type RatingAndColor = {
    energyRating: EnergyRating;
    color: string;
};

export function energyRatingLabelAndColor(energyRating: EnergyRating): RatingAndColor {
    switch (energyRating) {
        case 'A+++':
            return {
                energyRating,
                color: '#00A851',
            };
        case 'A++':
            return {
                energyRating,
                color: '#4FB947',
            };
        case 'A+':
            return {
                energyRating,
                color: '#C1D82D',
            };
        case 'A':
            return {
                energyRating,
                color: '#FEF200',
            };
        case 'B':
            return {
                energyRating,
                color: '#FCBA0B',
            };
        case 'C':
            return {
                energyRating,
                color: '#F3711A',
            };
        case 'D':
            return {
                energyRating,
                color: '#EE141F',
            };
        case 'Unknown': {
            return {
                energyRating,
                color: '#EE141F',
            };
        }
        default: {
            return {
                energyRating,
                color: '#EE141F',
            };
        }
    }
}

export type MappedSpecification = {
    label: string;
    value: string;
    unit?: string;
};

export type CarSpecification = hesselApiTypes.CarSpecification;

export type SpecificationGroup = {
    heading: string;
    specifications: Array<MappedSpecification | null>;
    images: Array<string>;
};

export type EquipmentResource = {
    id: string;
    name: string;
    data: string;
    colorCode?: string;
    colorName?: string;
    type: string;
    filename: string;
};

export type Equipment = {
    id: string;
    name: string;
    type?: string;
    description?: string;
    monthlyHirePrice?: number;
    monthlyPrivateLeasingPrice?: number;
    monthlyOperationalLeasingPrice?: number;
    monthlyFinancialLeasingPrice?: number;
    cashPrice?: number;
    showOnTechCenter: boolean;
    techCenterDescription?: string;
    techCenterLink?: string;
    idCodeShort?: string;
    resources: Array<EquipmentResource>;
    equipmentIdCode: string;
    ownershipStatus?: hesselApiTypes.VehicleEquipmentStatus;
    equipmentValue?: number;
} & { isSelected?: boolean };

export type EquipmentPackage = {
    id: string;
    type?: string;
    name?: string;
    description?: string;
    packageIdKey: string;
    packageIdValue: string;
    freeText?: string;

    monthlyHirePrice?: number;
    monthlyPrivateLeasingPrice?: number;
    monthlyOperationalLeasingPrice?: number;
    monthlyFinancialLeasingPrice?: number;
    cashPrice?: number;
    resources: Array<EquipmentResource>;
    equipment: Array<Equipment>;
    ownershipStatus?: hesselApiTypes.VehicleEquipmentStatus;
} & { isSelected?: boolean };

/**
 * NOTE: If there was a need to add Non-Hessel brands to this list, make sure to adjust promotion types for PDP
 */
export const BrandsConsts = {
    Dacia: 'Dacia',
    Ford: 'Ford',
    Mercedes: 'Mercedes-Benz',
    Renault: 'Renault',
} as const;

export type Brands = typeof BrandsConsts[keyof typeof BrandsConsts];

export type ProductColor = hesselApiTypes.CarColor;

export type ElCarBatteryInfo = {
    capacity: string;
    range: number;
};

export type CarConfiguration = {
    carTypes: Array<string>;
    fuelTypes: Array<string>;
    transmissionTypes: Array<string>;
    tractionWheels: Array<string>;
    lengthTypes: Array<string>;
    heightTypes: Array<string>;
    equipmentLines: Array<string>;
    batteryInfoList: Array<ElCarBatteryInfo>;
};

export type ProductDetailsLeasingPeriod = {
    value: string;
    displayValue: string;
};

export type CarType = '4-dørs Coupe' | 'All-Terrain' | 'Cabriolet' | 'Coupe' | 'Hatchback' | 'Sedan' | 'Stationcar' | 'SUV' | 'MPV';

export const VehicleTypeConsts = {
    0: 'Car',
    1: 'Van',
} as const;

export type VehicleType = typeof VehicleTypeConsts[keyof typeof VehicleTypeConsts];

export type EquipmentOwnership = {
    [key in OwnershipTab]: Array<Equipment>;
};

export type EquipmentPackageOwnership = {
    [key in OwnershipTab]: Array<EquipmentPackage>;
};

export type ProductCampaign = hesselApiTypes.VehicleCampaign;

export type TestDriveAvailability = 'Available' | 'NotAvailable' | 'AvailablePreBooking' | null;

export type ProductDetails = {
    id: string;
    url: string;
    canonicalUrl: string;
    vehicleType: VehicleType;
    itemNumber: string;
    description?: string;
    referenceNumber?: string;
    vin?: string;
    variantId: string;
    entityId: number;
    carType: CarType;
    fuelType: string;
    transmissionType: string;
    tractionWheels: string;
    batteryCapacity?: string;
    range?: number;
    availability: VehicleAvailability;
    brand: Brands;
    brandTitle: string;
    modelTitle: string;
    name: string;
    energyRating: EnergyRating;
    highlightedSpecs: Array<MappedSpecification>;
    resources: Array<PdpResource>;
    purchaseTypes: hesselApiTypes.VehiclePurchaseType;
    campaigns: Array<ProductCampaign>;
    leasingDurationsMonths: Array<number>;
    payment: number;
    pricePerMonth: number;
    specificationGroups: Array<SpecificationGroup>;
    variantTitle: string;
    location?: string;
    locationId?: string;

    // Dimensions
    length: number;
    lengthType: string;
    height: number;
    heightType: string;

    standardEquipmentPackages: EquipmentPackageOwnership;
    extraEquipmentPackages: EquipmentPackageOwnership;
    standardEquipment: EquipmentOwnership;
    extraEquipment: EquipmentOwnership;
    optionalEquipment: EquipmentOwnership;
    equipmentNames: Array<string>;

    colors: Array<ProductColor>;
    color: string;

    metaTitle?: string;
    metaDescription?: string;

    variantModelCode: string;

    brochureUrl?: string;
    starmarkLink?: string;
    fullEquipmentListUrl: string;
    generalCampaignLabels: Array<AutoDesktopLabel>;
    privateLeasing: {
        price: number | null;
    };
    equipmentLine: string | null;
    specifications: {
        consumption: string;
        co2Emission: string;
        greenOwnershipFee: string;
        odometerReadingInKm?: number;
        firstYearOfRegistration?: number;
        firstMonthAndYearOfRegistration?: string;
    };
    modelYear: string;
    testDrive: TestDriveAvailability;
    leasingPriceIncludesExtraBenefits: boolean; //EJH-3927 temp solution, more details in jira task
    discontinued: boolean;
};

export type AutoDesktopLabel = {
    id: string;
    text: string;
    backgroundColor: string;
    textColor: string;
};

export type OwnershipMode = 'Køb' | 'Leasing';

export const OwnershipTabArray = [
    'Car Kontant',
    'Car Finansiering',
    'Car HiRE',
    'Car Privat Leasing',
    'Van Kontant',
    'Van Finansiering',
    'Van Operationel Leasing',
    'Van Finansiel Leasing',
    'No Value', // This value is used when we are not on a PDP, and the logic is unable to set the proper tab
] as const;

export type OwnershipTab = typeof OwnershipTabArray[number];
