import { FC, useMemo } from 'react';
import { DateStyle, formatDate, increaseHoursAndMinutes } from '../../../utils/helpers';
import { Slot } from '../../shared/slot';

import { Header, TimeSlot } from '../time-slot-picker.styled';
import { isEqual } from 'date-fns';
import { BookingWorkshopsStore } from '../../../lib/state/booking-workshops';
import { BookingStepWorkshop } from '../../../lib/api/models/umbraco';
import { utcToZonedTime } from 'date-fns-tz';

type Props = {
    workshopContent: BookingStepWorkshop;
};

export const CustomerStays: FC<Props> = ({ workshopContent }) => {
    const { selectedWorkshop, selectedDate, selectedTimeSlotToStay, workshops } = BookingWorkshopsStore.useStoreState((state) => state);
    const { setTimeSlotToStay } = BookingWorkshopsStore.useStoreActions((actions) => actions);

    const workshopDate = useMemo(
        () => workshops.find(({ sabId }) => sabId === selectedWorkshop)?.availableDaysToStay?.find(({ date }) => isEqual(date, selectedDate)),
        [workshops, selectedWorkshop, selectedDate]
    );

    const getSelectedTime = () => {
        if (!selectedTimeSlotToStay) {
            return '-';
        }

        const end = increaseHoursAndMinutes(
            selectedTimeSlotToStay.startDateTime,
            selectedTimeSlotToStay.estimatedWorkHours,
            selectedTimeSlotToStay.estimatedWorkMinutes
        );
        const danishStartTime = utcToZonedTime(selectedTimeSlotToStay.startDateTime, 'Europe/Copenhagen');
        const danishEndTime = utcToZonedTime(end, 'Europe/Copenhagen');

        return selectedTimeSlotToStay && `${formatDate(danishStartTime, DateStyle.HH_mm)} - ${formatDate(danishEndTime, DateStyle.HH_mm)}`;
    };

    return (
        <>
            <Header>
                {workshopContent.timeSlotCustomerStaysHeader}: <strong style={{ fontWeight: 700 }}>{getSelectedTime()}</strong>
            </Header>

            <TimeSlot>
                {workshopDate?.timeSlots?.map((timeSlot, idx) => (
                    <Slot
                        key={`${idx}:${timeSlot.startDateTime}`}
                        isAvailable={timeSlot.available}
                        isSelected={isEqual(timeSlot.startDateTime, selectedTimeSlotToStay?.startDateTime ?? 0)}
                        time={timeSlot.startDateTime}
                        onClick={() => setTimeSlotToStay(timeSlot)}
                    />
                ))}
            </TimeSlot>
        </>
    );
};
