import { FC, useEffect, useMemo, useState } from 'react';
import { ShopStepProps } from '../shop-checkout-page.component';
import { Step } from '../../../hire/hire-lead-checkout/step';
import {
    StyledDeliveryOption,
    StyledDeliveryOptionHeader,
    StyledDeliveryOptionPrice,
    StyledDeliveryOptionWrapper,
    StyledDeliveryOptionsWrapper,
    StyledRadioButton,
} from './shop-delivery-step.styled';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { AlternativeDeliveryAddressForm, HomeDelivery } from './delivery-options/home-delivery.component';
import { ShopPickup } from './delivery-options/shop-pickup.component';
import { ProductInstallation } from './product-installation/product-installation.component';
import { DealershipInformationPage } from '../../../../lib/api/models/umbraco';
import { ParcelShopPickup } from './delivery-options/parcel-shop-pickup.component';
import { DeliveryOptionType } from '../../../../lib/api/models/shop/shop-api.models';
import { getDeliveryOptions } from '../../../../lib/api/shop/cart-api';
import { getShopPrice } from '../../../../utils/helpers';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { DeliveryOptionId } from '../../../../lib/api/models/shop/cart';
import { ParcelShop } from '../../../../lib/api/models/shop/parcel-shops';
import { StyledSubmittedInfo } from '../../../hire/hire-lead-checkout/typography';
import { SubmittedInfo } from '../../../shared/submitted-info';

export type InstallationRequest = {
    department?: DealershipInformationPage;
    date?: Date;
    timeslot?: string;
};

export type AlternativeDelivery = {
    alternativeDeliveryRequested: boolean;
    alternativeAddress: AlternativeDeliveryAddressForm;
};

export const ShopDeliveryStep: FC<ShopStepProps> = ({ currentStep, setActiveStep, page }) => {
    const [selectedDelivery, setSelectedDelivery] = useState(0);
    const [installationRequest, setInstallationRequest] = useState<InstallationRequest>();
    const [alternativeDelivery, setAlternativeDelivery] = useState<AlternativeDelivery>();
    const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptionType[]>([]);
    const [selectedParcelShop, setSelectedParcelShop] = useState<ParcelShop>();
    const [selectedDealership, setSelectedDealership] = useState<DealershipInformationPage>();
    const { shopCheckoutComplete, cart } = CartStoreContext.useStoreState((state) => state);
    const { setParcelShopPickupAsDelivery, setDealershipAsDelivery, setHomeDeliveryAsDelivery } = CartStoreContext.useStoreActions(
        (actions) => actions
    );

    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();

    useEffect(() => {
        if (currentStep === 2) tracker.deliveryStep();
    }, [currentStep, tracker]);

    const submitDelivery = async () => {
        if (cart === null) return;
        const delivery = deliveryOptions[selectedDelivery];
        tracker.setShipping(cart, delivery.name);
        if (delivery.name === 'PickUp_Point') {
            if (selectedParcelShop) await setParcelShopPickupAsDelivery({ cart, parcelShop: selectedParcelShop });
        } else if (delivery.name === 'PickUp_at_Hessel') {
            if (selectedDealership) await setDealershipAsDelivery({ cart, dealership: selectedDealership });
        } else if (delivery.name === 'Send_to_Address') {
            const alternativeDeliveryAddress =
                alternativeDelivery && alternativeDelivery.alternativeDeliveryRequested
                    ? {
                          addressLine1: alternativeDelivery.alternativeAddress.address.value,
                          city: alternativeDelivery.alternativeAddress.city.value,
                          name: alternativeDelivery.alternativeAddress.name.value,
                          zipCode: alternativeDelivery.alternativeAddress.zip.value,
                          countryCode: 'DK',
                          countryName: 'Danmark',
                      }
                    : null;
            await setHomeDeliveryAsDelivery({ cart, alternativeDeliveryAddress });
        }
    };
    const isComplete = useMemo(() => {
        return currentStep > 2;
    }, [currentStep]);

    const isCurrentStep = useMemo(() => {
        return currentStep === 2;
    }, [currentStep]);

    const hasInstallation = useMemo(() => {
        return deliveryOptions.length === 1 && deliveryOptions[0].name === 'Installation_at_Hessel';
    }, [deliveryOptions]);

    const isStepValid = useMemo(() => {
        if (hasInstallation && (!installationRequest?.date || !installationRequest?.timeslot || !installationRequest.department)) return false;
        if (
            alternativeDelivery &&
            alternativeDelivery.alternativeDeliveryRequested &&
            (!alternativeDelivery.alternativeAddress ||
                !alternativeDelivery.alternativeAddress.address.isValid ||
                !alternativeDelivery.alternativeAddress.city.isValid ||
                !alternativeDelivery.alternativeAddress.name.isValid ||
                !alternativeDelivery.alternativeAddress.zip.isValid)
        ) {
            return false;
        }
        return true;
    }, [hasInstallation, installationRequest, alternativeDelivery]);

    useEffect(() => {
        const getDeliveryOptionsAsync = async () => {
            if (!cart) return;
            const [result, error] = await getDeliveryOptions(cart.id);
            if (result && !error) {
                setDeliveryOptions(result);
                if (cart.delivery) {
                    const selectedDelivery = result.find((x) => x.name === cart.delivery?.name);
                    if (selectedDelivery) {
                        setSelectedDelivery(result.indexOf(selectedDelivery));
                    }
                }
            }
        };
        getDeliveryOptionsAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDeliveryOptionUi = (deliveryOptionName: DeliveryOptionId) => {
        switch (deliveryOptionName) {
            case 'Send_to_Address':
                return (
                    <HomeDelivery updateAlternativeDeliveryRequest={(request) => setAlternativeDelivery(request)} submitDelivery={submitDelivery} />
                );
            case 'PickUp_Point':
                return (
                    <ParcelShopPickup updateSelectedParcelShop={(parcelShop) => setSelectedParcelShop(parcelShop)} submitDelivery={submitDelivery} />
                );
            case 'PickUp_at_Hessel':
                return <ShopPickup updateSelectedDealership={(dealership) => setSelectedDealership(dealership)} submitDelivery={submitDelivery} />;
            default:
                break;
        }
    };

    const stepTitle = useMemo(() => {
        if (deliveryOptions.length === 0) return 'Levering / Montering';
        if (hasInstallation) return 'Montering';
        return 'Levering';
    }, [deliveryOptions.length, hasInstallation]);

    return (
        <Step
            edit={() => setActiveStep(2)}
            goNext={async () => {
                await submitDelivery();
                setActiveStep(3);
            }}
            isCompleted={isComplete}
            isCurrentStep={isCurrentStep}
            isStepLoading={false}
            isStepValid={isStepValid}
            setCanValidateStep={() => null}
            stepNumber={2}
            stepType={'Unknown'}
            title={stepTitle}
            totalSteps={4}
            isCheckoutComplete={shopCheckoutComplete}
            nextStep="Fortsæt"
        >
            {!isComplete && isCurrentStep && !hasInstallation ? (
                <StyledDeliveryOptionsWrapper>
                    {deliveryOptions.map((x, idx) => (
                        <StyledDeliveryOption key={x.name}>
                            <StyledDeliveryOptionHeader>
                                <StyledRadioButton
                                    checked={selectedDelivery === idx}
                                    id={x.name}
                                    value={x.name}
                                    subLabel={x.description}
                                    action={() => setSelectedDelivery(idx)}
                                >
                                    {x.label}
                                </StyledRadioButton>
                                <StyledDeliveryOptionPrice>{getShopPrice(x.discountedPrice)}</StyledDeliveryOptionPrice>
                            </StyledDeliveryOptionHeader>
                            {selectedDelivery === idx ? (
                                <StyledDeliveryOptionWrapper>{getDeliveryOptionUi(x.name)}</StyledDeliveryOptionWrapper>
                            ) : null}
                        </StyledDeliveryOption>
                    ))}
                </StyledDeliveryOptionsWrapper>
            ) : null}
            {!isComplete && isCurrentStep && hasInstallation ? (
                <div>
                    <ProductInstallation updateInstallationRequest={(req) => setInstallationRequest(req)} offsetDays={page.installationOffsetDays} />
                </div>
            ) : null}
            {isComplete ? (
                <div style={{ opacity: '0.4' }}>
                    <StyledSubmittedInfo>
                        <SubmittedInfo label={'Leveringsmetode'} text={`${deliveryOptions[selectedDelivery].label}`} overrideMaxWidth={true} />
                    </StyledSubmittedInfo>
                </div>
            ) : null}
        </Step>
    );
};
