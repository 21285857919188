import { FC, useMemo } from 'react';
import { useBenefitAgreementInfo } from '../../../../../hooks/booking/booking/use-benefit-agreement-info';
import { useAgreements } from '../../../../../hooks/use-agreements';
import { BookingFormStore, FormInput } from '../../../../../lib/state/booking-form';
import { BookingStepsStore } from '../../../../../lib/state/booking-steps';
import { filterStepType } from '../../../../../lib/state/booking-steps/booking-steps.helpers';
import { BookingWorkshopsStore } from '../../../../../lib/state/booking-workshops';
import { formInputLabel, formInputValue, vehicleName, customerAgreement } from '../../../../../utils/helpers';
import { CarInfo } from '../../../../shared/car-info';
import { SubmittedInfo } from '../../../../shared/submitted-info';
import { CarInfoWrapper, InfoWrapper, Wrapper } from './your-car-summary.styled';

type Props = {
    inputs: FormInput<string>[];
};

const YourCarSummary: FC<Props> = ({ inputs }) => {
    const { content } = BookingStepsStore.useStoreState((state) => ({
        content: state.steps
            .filter(filterStepType('YourCar'))
            .map(({ content }) => content)
            .reduce((acc, content) => ({ ...acc, ...content })),
    }));

    const { agreements, vehicle } = BookingFormStore.useStoreState(({ vehicle, customer }) => ({
        vehicle: vehicle,
        agreements: { benefitAgreement: customer?.benefitAgreement, serviceAgreement: vehicle?.serviceAgreement },
    }));

    const { registeredCarInfoLabel, registeredAgreementLabel, noAgreementsText } = content;

    const hasAgreements = useAgreements();

    const { workshops } = BookingWorkshopsStore.useStoreState(({ workshops }) => ({
        workshops,
    }));

    const submittedInfo = useMemo(
        () =>
            inputs.map((input) => ({
                id: input.id,
                value: input.type !== 'workshop' ? formInputValue(input) : workshops.find(({ sabId }) => sabId === input.value)?.name ?? '',
                label: formInputLabel(input),
            })),
        [inputs, workshops]
    );

    const benefitAgreementInfo = useBenefitAgreementInfo();

    return (
        <Wrapper>
            <InfoWrapper>
                {submittedInfo.map((val, idx) => (
                    <SubmittedInfo key={`${idx}:${val.id}`} label={val.label} text={val.value} />
                ))}
            </InfoWrapper>
            <CarInfoWrapper>
                {vehicle?.makeId && vehicle?.model && vehicle?.variant && (
                    <CarInfo
                        icon="car/car-side-view"
                        descriptionIconColor="defaultCircular"
                        checkmarkColor="success"
                        label={registeredCarInfoLabel}
                        text={vehicleName(vehicle?.makeId, vehicle?.model, vehicle?.variant)}
                    />
                )}

                {hasAgreements && (
                    <CarInfo
                        icon={'car/star'}
                        checkmarkColor="success"
                        descriptionIconColor="defaultCircular"
                        label={hasAgreements ? registeredAgreementLabel : noAgreementsText}
                        text={hasAgreements ? customerAgreement(content, agreements, benefitAgreementInfo?.agreementName) : ''}
                        success={hasAgreements}
                    />
                )}
            </CarInfoWrapper>
        </Wrapper>
    );
};

export default YourCarSummary;
