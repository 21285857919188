import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from '../../../icons';
import { IconButton } from '../../icon-button/icon-button.component';
import { StyledCarouselAspectRatioProps } from '../carousel.types';
import { InnerCarousel } from '../inner-carousel/inner-carousel.component';

export const StyledSimpleCarousel = styled.section`
    position: relative;
    background-color: #f4f4f4;
    &:focus-within > div,
    &:hover > div {
        opacity: 1;
    }
`;

export const StyledSimpleCarouselControls = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 12px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 300ms;
    z-index: 2;

    & > * {
        pointer-events: all;
    }
`;

export const StyledSimpleCarouselPreviousButton = styled(IconButton).attrs({
    children: ArrowLeft({}),
})``;

export const StyledSimpleCarouselNextButton = styled(IconButton).attrs({
    children: ArrowRight({}),
})``;

export const StyledSimpleInnerCarousel = styled(InnerCarousel).attrs<StyledCarouselAspectRatioProps>((attrs) => ({
    style: {
        aspectRatio: attrs.aspectRatio || '16 / 9',
    },
}))<StyledCarouselAspectRatioProps>`
    z-index: 1;
`;

export const StyledSimpleInnerCarouselCaption = styled.figcaption`
    font: 500 12px/14px ${({ theme }) => theme.typography.fontFamily}, Sans-serif;
    margin: 10px 0 0;
`;

export const StyledSimpleInnerCarouselImageThumbnailWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    position: relative;
    margin-top: 9px;
    & img {
        width: 100%;
        height: 100%;
    }
`;

export const StyledSimpleInnerCarouselImageThumbnail = styled.div`
    position: relative;
    background-color: rgb(247, 247, 248);
`;

export const StyledSimpleInnerCarouselImageCounter = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
    color: ${(props) => props.theme.palette.common.white};
    cursor: pointer;
`;

export const StyledSimpleCarouselZoomControl = styled.div`
    position: absolute;
    top: 24px;
    right: 30px;
    z-index: 2;
    cursor: pointer;
`;

export const StyledInfoTextWrapper = styled.div`
    bottom: 24px;
    z-index: 2;
    position: absolute;
    width: 100%;
`;
export const StyledInfoText = styled.span`
    background-color: #ebebeb;
    padding: 8px 12px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
`;
