import React, { FC, useMemo, useState } from 'react';
import { TextInput } from '../../../forms/inputs';
import { ServiceAgreementCalculatorModalStore } from '../../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { StyledButton } from '../step/step.styled';
import { LoadingWrapper } from '../../../shared/loading-wrapper';
import { SvgIcon } from '../../../shared/svg-icon';
import { vehicleName } from '../../../../utils/helpers';
import { Spinner } from '../../../shared/spinner';
import { config, useSpring } from 'react-spring';
import { getVehicleAndServiceAgreements } from '../../../../lib/api/service-agreement-calculator/hessel-service-agreement-calculator-api';
import {
    StyledBrandInfo,
    StyledBrandInfoItem,
    StyledBrandInfoItemDescription,
    StyledBrandInfoItemHeader,
    StyledErrorLabel,
    StyledLicensePlateContainer,
} from './license-plate-step.styled';
import { ErrorType } from '../../../../lib/api';
import { ServiceAgreementCalculatorStepOne } from '../../../../lib/api/models/umbraco';
import { VehicleAndServiceAgreementsResponse } from '../../../../lib/api/models/hessel-api';

type Props = {
    onNextStepClick: () => void;
    isCompleted: boolean;
    isOpen: boolean;
    stepSettings: ServiceAgreementCalculatorStepOne;
};

const LicensePlateStep: FC<Props> = ({ onNextStepClick, isCompleted, isOpen, stepSettings }) => {
    const { setVehicleInformation, setLicensePlate, setServiceAgreements } = ServiceAgreementCalculatorModalStore.useStoreActions(
        (actions) => actions
    );

    const { vehicleInformation } = ServiceAgreementCalculatorModalStore.useStoreState((state) => state);
    const [error, setError] = useState<string | null>();
    const [isLoading, setIsLoading] = useState(false);
    const [canValidateInput, setCanValidateInput] = useState(false);

    const isValid = useMemo(() => vehicleInformation?.licensePlate.length > 1, [vehicleInformation?.licensePlate]);

    const onClickAsync = async () => {
        setCanValidateInput(true);
        if (isValid) {
            setIsLoading(true);
            setError(null);

            const [result, error] = await getVehicleAndServiceAgreements(vehicleInformation.licensePlate);
            if (result && !error && result.vehicle && result.serviceAgreements) {
                setVehicleInformation(result.vehicle);
                setServiceAgreements(result.serviceAgreements);
                onNextStepClick();
            } else {
                handleError(error?.errorType, result);
            }
            setIsLoading(false);
        }
    };

    const handleError = (errorType?: ErrorType, response?: VehicleAndServiceAgreementsResponse) => {
        if (response !== null && (response?.serviceAgreements === null || response?.vehicle === null)) {
            setError('Vi har desværre ingen aftaler til denne Mercedes-Benz model');
            return;
        }

        switch (errorType) {
            case 'UnknownCar':
                setError('Bilen kan ikke findes');
                break;
            case 'UnsupportedCarBrand':
                setError('Denne nummerplade er ikke af mærket Mercedes-Benz');
                break;
            default:
                setError('Der skete en fejl, prøv venligst igen. Kontakt support ved gentagne fejl');
                break;
        }
    };

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: 1,
        delay: 100,
    });

    return (
        <>
            {isCompleted || isLoading ? (
                <StyledBrandInfo style={fadeContent}>
                    <StyledBrandInfoItem hasAgreements={false}>
                        {isLoading ? <Spinner variant="blue" size="small" /> : <SvgIcon iconName="checkmark" color="success" />}
                        <LoadingWrapper isLoading={isLoading}>
                            <SvgIcon iconName="car/car-side-view" color="defaultCircular" />
                        </LoadingWrapper>
                        <LoadingWrapper isLoading={isLoading}>
                            <StyledBrandInfoItemHeader>DIN BIL</StyledBrandInfoItemHeader>
                            <StyledBrandInfoItemDescription>{vehicleInformation.licensePlate}</StyledBrandInfoItemDescription>
                            <StyledBrandInfoItemDescription>
                                {!isLoading && vehicleInformation.make && vehicleInformation.model && vehicleInformation.variant
                                    ? vehicleName(vehicleInformation.make, vehicleInformation.model, vehicleInformation.variant)
                                    : 'Lorem ipsum dolor'}
                            </StyledBrandInfoItemDescription>
                        </LoadingWrapper>
                    </StyledBrandInfoItem>
                </StyledBrandInfo>
            ) : (
                <StyledLicensePlateContainer>
                    <TextInput
                        id="licensePlateStep"
                        label={stepSettings.inputLabel}
                        placeholder={stepSettings.inputPlaceholder}
                        type="car"
                        canValidateInputField={canValidateInput}
                        hideValidation={false}
                        isValid={isValid}
                        validationMessage="Venligst indtast en gyldig nummerplade"
                        onChange={(e) => setLicensePlate(e.target.value.trim())}
                        onInputBlur={() => setCanValidateInput(true)}
                        value={vehicleInformation.licensePlate}
                    />
                </StyledLicensePlateContainer>
            )}

            {error && <StyledErrorLabel>{error}</StyledErrorLabel>}

            {isOpen && !isLoading && (
                <StyledButton onClick={onClickAsync} variant="primary">
                    {stepSettings.ctaText}
                </StyledButton>
            )}
        </>
    );
};
export default LicensePlateStep;
