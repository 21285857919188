import Head from 'next/head';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { umbraco } from '../../lib/api';
import { BrandBarContent, FooterContent, orgTypes, SiteIcons } from '../../lib/api/models/umbraco';
import { HireContentDisplayStore } from '../../lib/state/hessel-site/display-manager';
import { EquipmentDialogManagerStore } from '../../lib/state/hessel-site/equipment-dialog-manager';
import { ProductDetailsStore } from '../../lib/state/hessel-site/product-details';
import { MEDIA_URL } from '../../utils/environment-constants';
import { EquipmentDialog } from '../product-details/equipment-dialog';
import { BrandBar } from '../shared/brand-bar';
import { AppFooter } from '../shared/footer';
import { MetaMenu } from '../shared/meta-menu/meta-menu.component';
import Script from 'next/script';
import { MegaMenu, MenuContext } from '../mega-menu/mega-menu.component';
import { useRouter } from 'next/router';
import { storePathValues } from '../../utils/helpers';
import { Breadcrumb } from '../shared';
import { CenteredBlock } from '../structural-blocks/centered-block.component';
import { StyledMegaMenuOverlay } from '../mega-menu/mega-menu.styled';
import { Transition } from 'react-spring';
import { MyHesselStarPlusHandler } from '../plus-sites-shared/content-handlers';
import ServiceAgreementCalculatorModal from '../organization/service-agreement-calculator-modal/service-agreement-calculator-modal.component';

export type HesselLayoutProps = {
    page: umbraco.Page;
    settings?: umbraco.SiteSettings;
    extraBreadcrumbContext?: string;
};

type HesselLayoutSettings = {
    brandBarContent: BrandBarContent;
    footerContent: FooterContent;
    siteIcons: SiteIcons;
    dealershipSettings: orgTypes.DealershipListSettings;
    menuSettings: umbraco.HireMenuSettings;
    miniBasketSettings: umbraco.HireMiniBasketSettings;
    headerSettings: umbraco.HireHeaderSettings;
    megaMenuSettings: umbraco.MegaMenuSettings | undefined;
    serviceAgreementCalculatorSettings: umbraco.ServiceAgreementCalculatorSettings | undefined;
    allowChatBot: boolean;
};

export const HesselLayout: FC<HesselLayoutProps> = ({ children, settings: umbracoSettings, page, extraBreadcrumbContext }) => {
    // Product details store
    const { selectedProduct, includedEquipmentText, selectedTab } = ProductDetailsStore.useStoreState((state) => state);

    // Equipment Dialog Manager
    const { showAllEquipment, selectedEquipment, selectedEquipmentPackage, selectedOptionalEquipment } = EquipmentDialogManagerStore.useStoreState(
        (actions) => actions
    );

    // Hire content display store
    const { userClosedMetaMenu } = HireContentDisplayStore.useStoreState((state) => ({
        userClosedMetaMenu: state.userClosedMetaMenu,
    }));
    const { setUserClosedMetaMenu } = HireContentDisplayStore.useStoreActions((actions) => ({
        setUserClosedMetaMenu: actions.setUserClosedMetaMenu,
    }));

    const { resetStore } = EquipmentDialogManagerStore.useStoreActions((actions) => actions);

    const settings = useMemo<HesselLayoutSettings>(() => {
        if (!umbracoSettings || !umbracoSettings.marketSettings.length) throw new Error('[HireLayout] No settings provided.');

        const marketSettings = umbracoSettings.marketSettings[0];

        return {
            brandBarContent: marketSettings.brandBarContent,
            footerContent: marketSettings.footer,
            siteIcons: marketSettings.siteIcons,
            dealershipSettings: marketSettings.dealershipSettings,
            menuSettings: marketSettings.menuSettings,
            miniBasketSettings: marketSettings.miniBasketSettings,
            headerSettings: marketSettings.hireHeaderSettings,
            megaMenuSettings: marketSettings.megaMenuSettings,
            allowChatBot: marketSettings.trackingSetup.allowChatbot,
            serviceAgreementCalculatorSettings: marketSettings.serviceAgreementCalculatorSettings,
        };
    }, [umbracoSettings]);

    const router = useRouter();
    const [menuContext, setMenuContext] = useState<MenuContext>(router.asPath.startsWith('/erhverv') ? 'erhverv' : 'privat');

    useEffect(() => {
        if (settings.megaMenuSettings && settings.megaMenuSettings.activateMegaMenu) {
            const handleRouteChange = (url: string) => {
                if (url.startsWith('/erhverv')) {
                    setMenuContext('erhverv');
                } else {
                    setMenuContext('privat');
                }
            };
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
    }, [router.events, settings.megaMenuSettings]);

    useEffect(() => storePathValues, [router.asPath]);

    const [overlayActive, setOverlayActive] = useState(false);

    const setNewOverlayState = (newState: boolean) => {
        setOverlayActive(newState);
    };

    return (
        <>
            <Head>
                <title>{page.metaTitle?.length > 0 ? page.metaTitle : page.name}</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1" />
                <meta name="description" content={page.metaDescription} />
                <link rel="icon" href={`${MEDIA_URL}/${settings.siteIcons?.favicon?.src}`} />
                {page.canonical && page.canonical.length > 0 ? <link rel="canonical" href={page.canonical} /> : null}
                {page.metaDescription?.length > 0 ? <meta name="description" content={page.metaDescription} /> : null}
                {page.hideFromGoogle === true ? <meta name="robots" content="noindex" /> : null}
            </Head>
            {/* Do not change Id="ze-snippet" */}
            {settings.allowChatBot ? (
                <Script
                    id="ze-snippet"
                    strategy="afterInteractive"
                    src="https://static.zdassets.com/ekr/snippet.js?key=c733182e-88e9-4afb-8acc-a575d74951bb"
                />
            ) : null}
            <MetaMenu
                metaMenu={page.pageMetaMenu ? page.pageMetaMenu[0] : undefined}
                onClose={() => setUserClosedMetaMenu(true)}
                isClosed={userClosedMetaMenu}
            />
            {settings.megaMenuSettings ? (
                <>
                    <Transition items={overlayActive} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }} delay={100}>
                        {(styles, item) => item && <StyledMegaMenuOverlay style={styles}></StyledMegaMenuOverlay>}
                    </Transition>
                    <MegaMenu
                        megaMenuSettings={settings.megaMenuSettings}
                        miniBasketSettings={settings.miniBasketSettings}
                        dealershipSettings={settings.dealershipSettings}
                        menuContext={menuContext}
                        pageId={page.id}
                        onMegaMenuChange={(newState: boolean) => setNewOverlayState(newState)}
                    />
                    <CenteredBlock>
                        <Breadcrumb
                            breadcrumbs={page.breadcrumbs}
                            currentPageName={page.name}
                            extraInfo={extraBreadcrumbContext && extraBreadcrumbContext.length > 0 ? extraBreadcrumbContext : undefined}
                        />
                    </CenteredBlock>
                </>
            ) : null}
            {children}
            {umbracoSettings ? <MyHesselStarPlusHandler siteSettings={umbracoSettings} /> : null}

            {settings.serviceAgreementCalculatorSettings && (
                <ServiceAgreementCalculatorModal settings={settings.serviceAgreementCalculatorSettings} />
            )}

            <EquipmentDialog
                visible={!!selectedEquipment || !!selectedEquipmentPackage || !!selectedOptionalEquipment || showAllEquipment}
                hideModal={() => {
                    resetStore();
                }}
                standardEquipmentList={selectedProduct?.standardEquipment[selectedTab]}
                standardEquipmentPackage={selectedProduct?.standardEquipmentPackages[selectedTab]}
                extraEquipmentList={selectedProduct?.extraEquipment[selectedTab]}
                extraEquipmentPackage={selectedProduct?.extraEquipmentPackages[selectedTab]}
                selectedEquipment={selectedEquipment}
                selectedEquipmentPackage={selectedEquipmentPackage}
                selectedOptionalEquipment={selectedOptionalEquipment}
                optionalEquipmentList={selectedProduct?.optionalEquipment[selectedTab]}
                availability={selectedProduct?.availability}
                includedEquipmentText={includedEquipmentText}
                showAllEquipment={showAllEquipment}
                equipmentNames={selectedProduct?.equipmentNames ?? []}
                giveImagesSquaredCorners={(page as umbraco.ProductDetailsPage).equipmentDialogSettings?.find(() => true)?.useSquaredCorners ?? false}
            />
            {settings.footerContent && <AppFooter {...settings.footerContent} menuContext={menuContext} />}
            {settings.brandBarContent && settings.brandBarContent.brandBarList.length > 0 ? (
                <BrandBar brandBarSettings={settings.brandBarContent} menuContext={menuContext} />
            ) : null}
        </>
    );
};
