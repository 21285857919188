import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledEquipmentSection = styled.div`
    & > div {
        border-bottom: 1px solid #d1d2d4;
    }
`;

export const StyledHeader = styled.h3`
    color: #0b0b0b;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 10px;
`;

export const AddOnInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 15px 0px;

    &:first-child {
        padding-top: 0px;
    }
`;

export const CheckboxAndReadMore = styled.div`
    display: grid;
    grid-template-areas:
        'checkbox price'
        'readmore readmore';
    grid-template-columns: 3fr auto auto;

    @media ${device.tablet} {
        grid-template-areas: 'checkbox readmore price';
        grid-template-columns: 3fr 0.5fr 113px;
    }
`;

export const StyledPrice = styled.p`
    color: #807f80;
    font-size: 13px;
    letter-spacing: 0;
    white-space: nowrap;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const StyledButtonText = styled.p`
    white-space: nowrap;
    font-size: 13px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const StyledCheckboxWrapper = styled.div`
    input {
        margin-top: 3px;
    }
`;

export const StyledEquipmentText = styled.p`
    font-size: 13px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const StyledEqCheckboxGridArea = styled.div`
    grid-area: checkbox;
`;

export const StyledEqReadMoreGridArea = styled.div`
    grid-area: readmore;
    padding-left: 27px;
    padding-right: 30px;
    @media ${device.tablet} {
        padding-left: 0px;
    }
`;
export const StyledEqPriceGridArea = styled.div`
    grid-area: price;
    text-align: right;
`;

export const StyledIncludedEquipmentItem = styled.div`
    display: flex;
    gap: 2px;
`;

export const StyledEquipmentSum = styled.div`
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    font-size: 14px;
    flex-direction: column;
    @media ${device.mobile_tablet} {
        flex-direction: row;
    }
`;
