import { FC, useState } from 'react';
import Image from 'next/image';
import { Transition } from 'react-spring';
import { StyledToolTipContainer, StyledToolTipText } from './tool-tip-content.styled';

type Props = {
    isOpen?: boolean; // If left undefined, the tooltip will control its own state.
    showIcon?: boolean;
    disableMinWidth?: boolean;
    openDirection?: 'left' | 'right';
};

export const ToolTipContent: FC<Props> = ({ isOpen, children, showIcon = true, disableMinWidth = false, openDirection = 'left' }) => {
    const [internalOpen, setInternalOpen] = useState(false);

    return (
        <StyledToolTipContainer
            onMouseEnter={() => (isOpen === undefined ? setInternalOpen(true) : null)}
            onMouseLeave={() => (isOpen === undefined ? setInternalOpen(false) : null)}
        >
            {showIcon ? <Image src="/icons/tooltip.svg" height={15} width={15} /> : null}
            <Transition items={isOpen || internalOpen} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }} delay={100} expires={true}>
                {(styles, item) =>
                    item && (
                        <StyledToolTipText disableMinWidth={disableMinWidth} style={styles} openDirection={openDirection}>
                            {children}
                        </StyledToolTipText>
                    )
                }
            </Transition>
        </StyledToolTipContainer>
    );
};
